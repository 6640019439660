<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        ripple
        depressed
        v-on="on"
        class="right"
      ><v-icon color="primary">edit</v-icon>
      </v-btn>
    </template>
    <v-card >
      <v-card-title
        class="title grey lighten-3"
        primary-title
      >Update Number
      </v-card-title>
      <v-card-text :class="{'px-3': $vuetify.breakpoint.smAndUp}">
        <v-form ref="updateNumberForm">
          <v-flex xs12>
            <v-text-field
              outline
              mask="#### ### #####"
              label="Phone Number"
              :rules="[rules.required]"
              v-model="updatedNumber"
            ></v-text-field>
          </v-flex>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-btn
          text
          @click="onCloseModal"
        >Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          :loading='isUpdating'
          @click="onUpdateModal"
        >Update Number
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rulesMixin from '@/mixins/rulesMixin'

export default {
  mixins: [rulesMixin],
  props: ['number', 'index'],
  data () {
    return {
      isUpdating: false,
      dialog: false,
      updatedNumber: ''
    }
  },
  methods: {
    onCloseModal () {
      this.dialog = false
      this.$refs.updateNumberForm.reset()
    },
    onUpdateModal () {
      if (this.$refs.updateNumberForm.validate()) {
        this.isUpdating = true
        let payload = {
          index: this.index,
          number: this.updatedNumber,
          control: 'update'
        }
        this.$store.dispatch('user/UPDATE_PHONE_NUMBER', payload)
          .then(res => {
            this.updatedNumber = ''
            this.$refs.updateNumberForm.resetValidation()
            this.dialog = false
            this.$swal({
              type: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 2000
            })
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'Something wrong in the server!',
              showConfirmButton: false,
              timer: 2000
            })
          })
          .finally(() => {
            this.isUpdating = false
          })
      }
    }
  },
  mounted () {
    this.updatedNumber = this.number
  }
}
</script>
