import Philippines from 'philippines'

export default {
  data () {
    return {
      provinces: [],
      cities: []
    }
  },
  methods: {
    getProvinces () {
      for (let i = 0; i < Philippines.provinces.length; i++) {
        this.provinces.push(Philippines.provinces[i].name)
      }
      this.provinces.sort()
    },
    populateCities () {
      this.cities = []
      var selectedProvinceKey
      for (var i = 0; i < Philippines.provinces.length; i++) {
        if (Philippines.provinces[i].name === this.province) {
          selectedProvinceKey = Philippines.provinces[i].key
        }
      }
      for (var x = 0; x < Philippines.cities.length; x++) {
        if (Philippines.cities[x].province === selectedProvinceKey) {
          this.cities.push(Philippines.cities[x].name)
        }
      }
    }
  }
}