<template>
  <v-dialog
    persistent
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        small
        class="right"
        depressed
        v-on="on"
        color="primary"
      >Add Number</v-btn>
    </template>
    <v-card>
      <v-card-title
        class="title grey lighten-3"
        primary-title
      >Add Number
      </v-card-title>
      <v-card-text :class="{'px-3 pt-3': $vuetify.breakpoint.mdAndUp, 'pt-3': $vuetify.breakpoint.smAndDown }">
        <v-form ref="addNumberForm">
          <v-flex xs12>
            <v-text-field
              outlined
              dense
              mask="#### ### #####"
              label="Phone Number"
              :rules="[rules.required]"
              v-model="contact.number"
            ></v-text-field>
          </v-flex>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-3">
        <v-btn
          text
          @click="onCloseModal"
        >Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          depressed
          color="primary"
          :loading='isAdding'
          @click="onAddModal"
        >Add Number
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rulesMixin from '@/mixins/rulesMixin'

export default {
  mixins: [rulesMixin],
  data () {
    return {
      isAdding: false,
      dialog: false,
      contact: {
        number: ''
      }
    }
  },
  methods: {
    onCloseModal () {
      this.dialog = false
      this.$refs.addNumberForm.reset()
    },
    onAddModal () {
      if (this.$refs.addNumberForm.validate()) {
        this.isAdding = true
        this.$store.dispatch('user/UPDATE_PHONE_NUMBER', this.contact)
          .then(res => {
            this.contact = {}
            this.$refs.addNumberForm.resetValidation()
            this.dialog = false
            this.$swal({
              type: 'success',
              title: res.message,
              showConfirmButton: false,
              timer: 2000
            })
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'Something went wrong in the server!',
              showConfirmButton: false,
              timer: 2000
            })
          })
          .finally(() => {
            this.isAdding = false
          })
      }
    }
  }
}
</script>
