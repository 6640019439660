<template>
  <v-card>
    <p class="font-weight-medium pt-3 px-3">Contact Details</p>
    <span class="caption px-3 grey--text">Phone Numbers</span>
    <v-row v-for="(number, index) in phoneNumbers" :key="number.key" align="center" class="px-3" no-gutters>
      <v-col cols="8">
        <p mask="#### #### #### ####" class="mb-0">{{ number }}</p>
      </v-col>
      <v-col cols="4" class="text-right">
        <UpdateModal :number="number" :index="index"/>
        <v-btn icon ripple @click="onClear(index, number)">
          <v-icon color="grey lighten-1">clear</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-col v-if="phoneNumbers" cols="12" class="text-right">
      <AddModal v-if="phoneNumbers.length < 3"/>
    </v-col>
    <v-divider></v-divider>
    <v-row no-gutters>
      <p class="font-weight-medium pt-3 px-3">Complete Address</p>
      <v-form ref="addressForm" class="px-3">
        <v-row no-gutters>
          <v-col cols="12">
            <v-text-field
              outlined
              dense
              label="House Number and Street Name"
              :rules="addressRule"
              v-model="houseNumStreet"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-select
              outlined
              dense
              label="City/Municipality"
              :items="cities"
              :rules="addressRule"
              v-model="city"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              label="Barangay"
              :rules="addressRule"
              @change="changed('address')"
              v-model="barangay"
            ></v-text-field>
          </v-col>
          <v-col cols="6" class="pr-1">
            <v-select
              outlined
              dense
              label="Province"
              :rules="addressRule"
              :items="provinces"
              @change="populateCities()"
              v-model="province"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              outlined
              dense
              label="Postal Code"
              :rules="addressRule"
              v-model="postalCode"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
    <v-col cols="12" class="text-right pt-0">
      <v-btn
        small
        depressed
        class="right"
        color="primary"
        :loading="isSaving"
        @click="onSaveAddress"
      >Save Address
      </v-btn>
    </v-col>
  </v-card>
</template>

<script>
import AddModal from './AddNumberModal'
import UpdateModal from './UpdateNumberModal'
import ProvinceAndCitiesMixin from '@/mixins/citiesAndProvinceMixin'

export default {
  components: {
    AddModal,
    UpdateModal
  },
  mixins: [ ProvinceAndCitiesMixin ],
  data () {
    return {
      isSaving: false,
      addressRule: [
        (v) => !!v || 'Please enter an address.'
      ]
    }
  },
  methods: {
    changed (address) {
      this.addressUnChanged = false
    },
    onClear (index, number) {
      let payload = {
        index: index,
        number: number,
        control: 'delete'
      }
      this.$swal({
        title: 'Are you sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f5395c',
        cancelButtonColor: '#8395A7',
        confirmButtonText: 'Delete',
        onOpen: () => { document.activeElement.blur() }
      })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch('user/UPDATE_PHONE_NUMBER', payload)
              .then(res => {
                if (res.message) {
                  this.$swal({
                    type: 'success',
                    title: res.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
                } else {
                  this.$swal({
                    type: 'error',
                    title: 'Something went wrong in the server!'
                  })
                }
              })
          }
        })
    },
    onSaveAddress () {
      if (this.$refs.addressForm.validate()) {
        this.isSaving = true
        this.$store.dispatch('user/UPDATE_COMPLETE_ADDRESS')
          .then(res => {
            this.$swal({
              type: 'success',
              text: 'Successfully updated the Complete Address.',
              showConfirmButton: false,
              timer: 2000
            })
          })
          .catch(err => {
            this.$swal({
              type: 'error',
              text: 'Something went wrong in the server!',
              showConfirmButton: false,
              timer: 2000
            })
          })
          .finally(() => {
            this.isSaving = false
          })
      }
    }
  },
  computed: {
    phoneNumbers: {
      get () {
        return this.$store.getters['user/user'].user.contacts
      },
      set () {
      }
    },
    province: {
      get () {
        return this.$store.getters['user/user'].user.province
      },
      set (value) {
        this.$store.commit('user/SET_PROVINCE', value)
      }
    },
    city: {
      get () {
        return this.$store.getters['user/user'].user.city
      },
      set (value) {
        this.$store.commit('user/SET_CITY', value)
      }
    },
    barangay: {
      get () {
        return this.$store.getters['user/user'].user.barangay
      },
      set (value) {
        this.$store.commit('user/SET_BARANGAY', value)
      }
    },
    houseNumStreet: {
      get () {
        return this.$store.getters['user/user'].user.houseNumAndStreet
      },
      set (value) {
        this.$store.commit('user/SET_HOUSE_AND_STREET', value)
      }
    },
    postalCode: {
      get () {
        return this.$store.getters['user/user'].user.postalCode
      },
      set (value) {
        this.$store.commit('user/SET_POSTAL_CODE', value)
      }
    }
  },
  created () {
    this.getProvinces()
    this.populateCities()
  }
}
</script>
